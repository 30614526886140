import "./App.css"
import { NavRoutes } from "./routes"

function App() {
  return (
    <div className="App">
      <NavRoutes />
    </div>
  )
}

export default App
